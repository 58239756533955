<template>
  <div>
    <modal v-model:show="showModal" size="lg">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">แก้ไขข้อมูล</h3>
          </div>
        </template>
        <div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="fname_id" class="form-control-label">ชื่อ</label>
                <input
                  id="fname_id"
                  name="fname"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.fname"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="lname_id" class="form-control-label">นามสกุล</label>
                <input
                  id="lname_id"
                  name="lname"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.lname"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="txtline_id" class="form-control-label"
                  >ไอดีไลน์</label
                >
                <input
                  id="txtline_id"
                  name="line_id"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.line_id"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="txtline_id" class="form-control-label"
                  >สิทธิ์วงล้อ</label
                >
                <input
                  id="spinto_id"
                  name="spinto_id"
                  type="number"
                  class="form-control input-group-alternative"
                  v-model="Form.spintoken"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="phone_id" class="form-control-label"
                  >เบอร์โทรศัพท์</label
                >
                <input
                  id="phone_id"
                  name="phone"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.phone"
                  readonly
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="twn_id" class="form-control-label"
                  >วอลเล็ท ID</label
                >
                <input
                  id="twn_id"
                  name="twn"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.twn"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="bank_id" class="form-control-label">ธนาคาร</label>
                <select
                  class="form-control input-group-alternative"
                  id="bank_id"
                  name="bank"
                  v-model="Form.bank"
                >
                  <option selected value="">-- เลือกธนาคาร --</option>
                  <option value="กรุงเทพ">ธนาคารกรุงเทพ จำกัด (มหาชน)</option>
                  <option value="กสิกรไทย">ธนาคารกสิกรไทย จำกัด (มหาชน)</option>
                  <option value="กรุงไทย">ธนาคารกรุงไทย จำกัด (มหาชน)</option>
                  <option value="ทหารไทยธนชาต">
                    ธนาคารทหารไทยธนชาต จำกัด (มหาชน)
                  </option>
                  <option value="ไทยพาณิชย์">
                    ธนาคารไทยพาณิชย์ จำกัด (มหาชน)
                  </option>
                  <option value="ซิตี้แบงค์">ธนาคารซิตี้แบงก์ เอ็น.เอ.</option>
                  <option value="แสตนดาร์ดชาร์เตอร์">
                    ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)
                  </option>
                  <option value="ซีไอเอ็มบีไทย">
                    ธนาคารซีไอเอ็มบี ไทย จำกัด (มหาชน)
                  </option>
                  <option value="ยูโอบี">ธนาคารยูโอบี จำกัด (มหาชน)</option>
                  <option value="กรุงศรีฯ">
                    ธนาคารกรุงศรีอยุธยา จำกัด (มหาชน)
                  </option>
                  <option value="ออมสิน">ธนาคารออมสิน</option>
                  <option value="ธอส">ธนาคารอาคารสงเคราะห์</option>
                  <option value="ธกส">
                    ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร
                  </option>
                  <option value="อิสลาม">ธนาคารอิสลามแห่งประเทศไทย</option>
                  <option value="ทิสโก้">ธนาคารทิสโก้ จำกัด (มหาชน)</option>
                  <option value="เกียรตินาคินภัทร">
                    ธนาคารเกียรตินาคินภัทร จำกัด (มหาชน)
                  </option>
                  <option value="ไอซีบีซี">
                    ธนาคารไอซีบีซี (ไทย) จำกัด (มหาชน)
                  </option>
                  <option value="ไทยเครดิต">
                    ธนาคารไทยเครดิต เพื่อรายย่อย จำกัด (มหาชน)
                  </option>
                  <option value="LHBANK">
                    ธนาคารแลนด์ แอนด์ เฮ้าส์ จำกัด (มหาชน)
                  </option>
                  <option value="truewallet">ทรูมันนี่ วอลเล็ท</option>
                </select>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="banknumber_id" class="form-control-label"
                  >เลขบัญชีธนาคาร</label
                >
                <input
                  id="banknumber_id"
                  name="banknumber"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.bankNumber"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="password_id" class="form-control-label"
                  >รหัสผ่าน</label
                >
                <input
                  id="password_id"
                  name="password"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="Form.password"
                />
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="status_id" class="form-control-label"
                  >เปิด / ปิด ยูสเซอร์</label
                >
                <select
                  class="form-control input-group-alternative"
                  id="status_id"
                  name="status"
                  v-model="Form.status"
                >
                  <option selected value="">-- เลือก --</option>
                  <option value="1">เปิด</option>
                  <option value="0">ปิด</option>
                </select>
              </div>
            </div>
          </div>
          <div class="text-right">
            <button
              type="submit"
              class="btn btn-primary"
              @click="onClickcheck()"
            >
              ยืนยัน
            </button>
            <base-button type="danger" @click="onClickCloseButton()"
              >ปิด</base-button
            >
          </div>
        </div>
      </card>
    </modal>
    <modal v-model:show="authModal" size="sm">
      <card
        type="secondary"
        header-classes="bg-transparent border-0"
        class="border-0 mb-0"
      >
        <template v-slot:header>
          <div class="text-center">
            <h3 class="modal-title">Authentication Required</h3>
          </div>
        </template>
        <div>
          <div class="form-group">
            <label for="auth_token" class="form-control-label"
              >Enter Authentication Token</label
            >
            <input
              id="auth_token"
              type="text"
              class="form-control input-group-alternative"
              v-model="ownerOtp"
            />
          </div>
          <div class="text-right">
            <button
              type="button"
              class="btn btn-primary"
              @click="verifyAuthToken"
            >
              Verify
            </button>
            <base-button type="danger" @click="authModal = false"
              >Cancel</base-button
            >
          </div>
        </div>
      </card>
    </modal>
  </div>
</template>

<script>
import { HTTP } from "@/services/http-common";
import modal from "../../Modal.vue";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { useToast } from "vue-toastification";
import Notification from "../../Notification.vue";

export default {
  components: {
    flatPicker,
    modal,
  },
  props: { show: Boolean, id: String },
  data() {
    return {
      showModal: false,
      authModal: false,
      ownerOtp: "",
      originalSpintoken: 0,
      userId: "",
      Form: {
        fname: "",
        lname: "",
        line_id: "",
        spintoken: 0,
        phone: "",
        twn: "",
        bank: "",
        bankNumber: "",
        password: "",
        status: "",
      },
    };
  },
  methods: {
    onClickcheck() {
      // Parse the current spin token input value
      const spintokenValue =
        this.Form.spintoken === "" ? 0 : parseInt(this.Form.spintoken, 10);

      // Compare with the original value stored in Form.spintoken
      // Assuming `originalSpintoken` was set when user data was fetched
      const originalSpintoken = this.originalSpintoken;

      // Check if the spin token value has increased
      if (spintokenValue > originalSpintoken) {
        this.authModal = true; // Show authentication modal if increased
      } else {
        this.onClickSubmit(); // Directly submit if not increased
      }
    },
    verifyAuthToken() {
      HTTP.post(
        "/admin/VerifyOwner?confirmownerotp",
        {
          otp: this.ownerOtp,
        },
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("adb_token"),
          },
        }
      )
        .then((resp) => {
          if (resp.status === 200) {
            this.authModal = false;
            this.ownerOtp = "";
            this.onClickSubmit();
          } else if (resp.status === 202) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined ? resp.data.msg : "AUTH OTP ไม่ถูกต้อง"
            );
          } else {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          }
        })
        .catch((err) => {
          // Handling potential errors during OTP verification
          this.runToast(
            "top-right",
            "danger",
            "เกิดข้อผิดพลาดในการตรวจสอบ OTP"
          );
        });
    },
    onClickSubmit() {
      const spintokenValue =
        this.Form.spintoken === "" ? 0 : parseInt(this.Form.spintoken, 10);
      if (
        this.Form.fname != "" &&
        this.Form.lname != "" &&
        this.Form.phone != "" &&
        this.Form.bank != "" &&
        spintokenValue >= 0 &&
        this.Form.password != "" &&
        this.Form.status != ""
      ) {
        HTTP.put(
          "/admin/member?id=" + this.userId,
          {
            fname: this.Form.fname,
            lname: this.Form.lname,
            line_id: this.Form.line_id,
            spintoken: spintokenValue,
            phone: this.Form.phone,
            password: this.Form.password,
            bank_number: this.Form.bankNumber,
            bank_name: this.Form.bank,
            tmn_id: this.Form.twn,
            status: this.Form.status,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("adb_token"),
            },
          }
        )
          .then((resp) => {
            if (resp.data.status != undefined && !resp.data.status) {
              this.runToast(
                "top-right",
                "danger",
                resp.data.msg != undefined
                  ? resp.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            } else {
              this.runToast(
                "top-right",
                "success",
                resp.data.msg != undefined ? resp.data.msg : "ทำรายการสำเร็จ"
              );
              this.onClickCloseButton();
            }
          })
          .catch((err) => {
            if (err.response) {
              if (err.response.status === 401) {
                this.$router.push("/login");
                this.$store.dispatch("logoutAuth");
              } else {
                this.runToast(
                  "top-right",
                  "danger",
                  err.response.data.msg != undefined
                    ? err.response.data.msg
                    : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
                );
              }
            }
          });
      } else {
        this.runToast("top-right", "danger", "ข้อมูลไม่ครบถ้วน");
      }
    },
    fetchUserData(val) {
      HTTP.get("/admin/member?id=" + this.userId, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("adb_token"),
        },
      })
        .then((resp) => {
          if (resp.data.status != undefined && !resp.data.status) {
            this.runToast(
              "top-right",
              "danger",
              resp.data.msg != undefined
                ? resp.data.msg
                : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
            );
          } else {
            if (val) {
              this.Form.fname = resp.data.data.fname;
              this.Form.lname = resp.data.data.lname;
              this.Form.line_id = resp.data.data.line_id;
              this.Form.spintoken = resp.data.data.game_token;
              this.originalSpintoken = resp.data.data.game_token;
              this.Form.phone = resp.data.data.phone;
              this.Form.twn = resp.data.data.tmn_id;
              this.Form.bank = resp.data.data.bank_name;
              this.Form.bankNumber = resp.data.data.bank_number;
              this.Form.password = resp.data.data.password;
              this.Form.status = resp.data.data.status;
              this.showModal = val;
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 401) {
              this.$router.push("/login");
              this.$store.dispatch("logoutAuth");
            } else {
              this.runToast(
                "top-right",
                "danger",
                err.response.data.msg != undefined
                  ? err.response.data.msg
                  : "ไม่สามารถทำรายการได้ กรุณาลองใหม่"
              );
            }
          }
        });
    },
    resetFormData() {
      this.Form.fname = "";
      this.Form.lname = "";
      this.Form.line_id = "";
      this.Form.spintoken = 0;
      this.Form.phone = "";
      this.Form.twn = "";
      this.Form.bank = "";
      this.Form.bankNumber = "";
      this.Form.password = "";
      this.Form.status = "";
      this.originalSpintoken = 0;
    },
    onClickCloseButton(event) {
      this.$emit("CloseModalEditUser", false);
    },
    runToast(pos, type, ownText, ownIcon) {
      const content = {
        component: Notification,
        props: {
          ownText: ownText,
          ownIcon: ownIcon,
          text: type,
          type: type,
        },
      };
      const toast = useToast();
      toast(content, {
        hideProgressBar: true,
        icon: false,
        closeButton: false,
        position: pos,
      });
    },
  },
  watch: {
    show: function (val) {
      if (val === true) {
        this.resetFormData();
        this.userId = this.id;
        this.fetchUserData(val);
      } else {
        this.showModal = val;
      }
    },
  },
};
</script>

<style></style>
